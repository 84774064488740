import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import swal from "sweetalert2";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated()) {
      return this.authService.isAuthenticated();
    } else {
      this.router.navigate(['/']);
      return false;
    }
  }
}



@Injectable()
export class VendorApprovalAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isVMApproval() || this.authService.isAdmin())) {
      return true;
    } else {
      this.router.navigate(['/pages/error']);
      return false;
    }
  }
}

@Injectable()
export class ApProcessorAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPProcessor() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}

@Injectable()
export class ApNewInvoiceAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPProcessor() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}

@Injectable()
export class ApPendingApprovalAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPProcessor() || this.authService.isAPApprover() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}

@Injectable()
export class ApApprovedAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPApprover() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}

@Injectable()
export class ApExtractedAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPApprover() || this.authService.isAPExporter() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}

@Injectable()
export class ApClosedAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isAPApprover() || this.authService.isAPExporter() || this.authService.isAdmin())) {
      return true;
    } else {
      swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      //this.router.navigate(['/pages/error']);

      this.router.navigate(['/dashboard']);

      return false;
    }
  }
}





//traffix Admin
@Injectable()
export class AdminAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && this.authService.isAdmin()) {
      return true;
    } else {
      this.router.navigate(['/pages/error']);
      return false;
    }
  }
}

//traffix quick view report
@Injectable()
export class traffixQuickViewReportAccess implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isTraffixReports() )) {
      return true;
    } else {
     // swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      this.router.navigate(['/pages/error']);

      return false;
    }
  }
}

//traffix all reports
@Injectable()
export class traffixAllReportsAccess implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isTraffixReports())) {
      // console.log("truee", this.authService.isCSReports() , this.authService.isAdmin())
      return true;
    } else {
      console.log("falsee")
     // swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      this.router.navigate(['/pages/error']);

      return false;
    }
  }
}

//traffix CustomerBillingRep page
@Injectable()
export class traffixCustomerBillingRepAccess implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isTraffixAdmin() || this.authService.isAdmin())) {
      return true;
    } else {
      //swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      this.router.navigate(['/pages/error']);

      return false;
    }
  }
}

//traffix Dashboard, details, listing page, email page
@Injectable()
export class traffixUserAccess implements CanActivate {

  constructor(private authService: AuthService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.isAuthenticated() && (this.authService.isUser()|| this.authService.isAdmin())) {
      return true;
    } else {
     // swal.fire("Access Denied!!", "Please contact your admin for further details", "warning");
      this.router.navigate(['/pages/error']);

      return false;
    }
  }
}
