import { Injectable } from "@angular/core";
import { RouteInfo } from '../vertical-menu/vertical-menu.metadata';
import * as CryptoJS from 'crypto-js';
import { secretKey } from "../data/urls";
import { mailboxes } from "../data/urls";

@Injectable({
  providedIn: "root"
})
export class SideNavService {
  public routeInfo: RouteInfo[];

  constructor() {
    this.setSideNavValues();
  }

  IsInRole(role: string) {
    var roles = (CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8)) != null ? JSON.parse(CryptoJS.AES.decrypt(localStorage.getItem('Roles'), secretKey).toString(CryptoJS.enc.Utf8)) : [];
    // console.log("Roles",roles)
    if (roles.includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  setSideNavValues() {
    //Sidebar menu Routes and data
    this.routeInfo = [

      {
        path: '/dashboard', title: 'Dashboard', icon: 'ft-home', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, Role: this.IsInRole('USER') || this.IsInRole('ADMIN'), submenu: []
      },
      {
        path: '/load/details', title: 'Load Details', icon: 'ft-file-text', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1 text-black', isExternalLink: false, Role: this.IsInRole('USER') || this.IsInRole('ADMIN'), submenu: []
      },
      {
        path: '', title: 'Emails', icon: 'ft-mail', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('USER') || this.IsInRole('ADMIN'),
        submenu: [
          { path: `/load/emails/${mailboxes.FIRST_MAILBOX_NAME}`, title: mailboxes.FIRST_MAILBOX_NAME, icon: 'ft-arrow-right-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: true, submenu: [] },
          { path:`/load/emails/${mailboxes.SECOND_MAILBOX_NAME}`, title: mailboxes.SECOND_MAILBOX_NAME, icon: 'ft-arrow-right-circle', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], Role:true},
          
        ]
      },

      {
        path: '', title: 'Admin', icon: 'ft-aperture', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('ADMIN'),
        submenu: [
          {
            path: '', title: 'Users', icon: 'ft-users submenu-icon', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('ADMIN'),
            submenu: [
              { path: '/admin/register', title: 'Register User', icon: 'ft-user-plus submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: true, submenu: [] },
              { path: '/admin/view-users', title: 'User Details', icon: 'ft-users submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], Role: true },
            ]
          },
          //{ path: '/admin/config', title: 'Config Settings', icon: 'ft-settings submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('ADMIN'), submenu: [] },
          { path: '/admin/roles', title: 'Roles', icon: 'ft-user-check submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, submenu: [], Role:this.IsInRole('ADMIN')},
         // { path: '/admin/customersandbillingrep', title: 'Customers Billing rep', icon: 'ft-file-text submenu-icon', class: 'text-info', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, submenu: [], Role: this.IsInRole('ADMIN') || this.IsInRole('TRAFFIX_ADMIN')},
        ]
      },

      {
        path: '', title: 'Reports', icon: 'icon-book-open', class: 'has-sub', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('TRAFFIX_REPORTS'),
        submenu: [
          { path: '/reports/quickViewReports', title: 'Quick View Report', icon: 'ft-bar-chart-2 submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: true, submenu: [] },
          //{ path: '/reports/headerExtractionDetails', title: 'Header Extraction', icon: 'ft-book submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: ( this.IsInRole('TRAFFIX_REPORTS')), submenu: [] },
         // { path: '/reports/documentLabellingDetails', title: 'Document Labelling', icon: 'ft-book submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: ( this.IsInRole('TRAFFIX_REPORTS')), submenu: [] },
          //{ path: '/reports/emailExceptionReport', title: 'Email Exceptions', icon: 'ft-alert-octagon submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: (this.IsInRole('TRAFFIX_REPORTS')|| this.IsInRole('CS_REPORTS')) , submenu: [] },
          //{ path: '/reports/traffixExceptionReport', title: 'Traffix Exceptions', icon: 'ft-alert-octagon submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:  (this.IsInRole('TRAFFIX_REPORTS')|| this.IsInRole('CS_REPORTS')), submenu: [] },
          { path: '/reports/newLoadsReport', title: 'New Queue Report', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role: this.IsInRole('TRAFFIX_REPORTS'), submenu: [] },
          //{ path: '/reports/approvedLoadsReport', title: 'Approved Loads', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:   this.IsInRole('CS_REPORTS'), submenu: [] },
          { path: '/reports/exceptionLoadsReport', title: 'Exception Loads', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:  this.IsInRole('TRAFFIX_REPORTS'), submenu: [] },
          { path: '/reports/dailyReport', title: 'User Activity Reports', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:  this.IsInRole('TRAFFIX_REPORTS'), submenu: [] },
          { path: '/reports/carrierBillingReport', title: 'Carrier Billing Reports', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:  this.IsInRole('TRAFFIX_REPORTS'), submenu: [] },
          { path: '/reports/activityReport', title: 'Activity Report', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:   this.IsInRole('TRAFFIX_REPORTS'), submenu: [] },
          //{ path: '/reports/traffixActivityReport', title: 'Traffix Activity Report', icon: 'ft-arrow-right submenu-icon', class: '', badge: '', badgeClass: '', isExternalLink: false, Role:   this.IsInRole('CS_REPORTS'), submenu: [] }
        ]
      },
      // {
      //   path: '/faq', title: 'FAQ', icon: 'ft-help-circle', class: '', badge: '', badgeClass: 'badge badge-pill badge-danger float-right mr-1 mt-1', isExternalLink: false, Role: true, submenu: []
      // }
    ];
  }
}
