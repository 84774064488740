// web-socket.service.ts

import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { webSocketURL } from '../data/urls';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket;

  getSocket() {
    return this.socket;
  }

  connect(): void {
    this.socket = io(webSocketURL.URL);
  }

  disconnect(): void {
    if (this.socket) {
      this.socket.disconnect();
    }
  }
}
