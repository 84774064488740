import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer.

export const Full_ROUTES: Routes = [
  {
    path: 'dashboard',
    loadChildren: () => import('../../dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('../../calendar/calendar.module').then(m => m.CalendarsModule)
  },
  {
    path: 'load',
    loadChildren: () => import('../../load/load.module').then(m => m.LoadModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('../../admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'analytics',
    loadChildren: () => import('../../analytics/analytics.module').then(m => m.AnalyticModule)
  },
  {
    path: 'exceptions',
    loadChildren: () => import('../../exceptions/exceptions.module').then(m => m.ExceptionsModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('../../faq/faq.module').then(m => m.FAQModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('../../reports/reports.module').then(m => m.ReportsModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('../../pages/full-pages/full-pages.module').then(m => m.FullPagesModule)
  }
];
