import { Component, Inject } from '@angular/core';
// import { AuthService } from './core/auth/auth.service';
import { MsalService, MsalBroadcastService, MSAL_GUARD_CONFIG, MsalGuardConfiguration } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
import { apiURL, secretKey } from './shared/data/urls';
import { AuthService } from './shared/auth/auth.service';
import * as CryptoJS from 'crypto-js';
import swal from 'sweetalert2';
import { support } from "./shared/data/urls";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})

export class AppComponent {
  mailTo = support.mailTo
  isIframe = false;
  loginDisplay = false;
  loggedInUserInfo: any;
  decodedToken: any;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private http: HttpClient,
    private authTs: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.isIframe = window !== window.parent && !window.opener;
    this.authService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )
      .subscribe((result: EventMessage) => {
        if (this.authService.instance.getAllAccounts().length === 0) {
        } else {
          this.setLoginDisplay();
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.setLoginDisplay();
        this.checkAndSetActiveAccount();
        if (!localStorage.getItem('IsAuthSuccessful')) {
          this.getMyProfile();
        } else {
          this.loggedInUserInfo = localStorage.getItem('UserEmail');
        }
      })
  }

  setLoginDisplay() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  checkAndSetActiveAccount() {
    let activeAccount = this.authService.instance.getActiveAccount();
    if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
      let accounts = this.authService.instance.getAllAccounts();
      this.authService.instance.setActiveAccount(accounts[0]);
    }
  }

  loginRedirect() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
    } else {
      this.authService.loginRedirect();
    }
  }

  logoutRedirect() {
    this.authService.logoutRedirect();
  }

  loginPopup() {
    if (this.msalGuardConfig.authRequest) {
      this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    } else {
      this.authService.loginPopup()
        .subscribe((response: AuthenticationResult) => {
          this.authService.instance.setActiveAccount(response.account);
        });
    }
  }

  async getMyProfile() {
    var userName = this.authService.instance.getActiveAccount().username
    this.loggedInUserInfo = userName;
    const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0/me";
    this.http.get(GRAPH_ENDPOINT)
      .toPromise().then(profile => {
        this.loggedInUserInfo = profile
        var body = {
          userPrincipleName: this.loggedInUserInfo.userPrincipalName
        }
      });
    await this.loginUser(userName);
  }

  async loginUser(uname: string) {
    return new Promise(async (resolve, reject) => {
      try {
        const encryptedValueForURL = CryptoJS.AES.encrypt(apiURL.URL, secretKey).toString();

        localStorage.setItem('BaseURL', encryptedValueForURL);
        const encryptedValueForReclassificationApiURL = CryptoJS.AES.encrypt(apiURL.RECLASSIFICATION_API_URL, secretKey).toString();
        localStorage.setItem('ReclassificationApiURL', encryptedValueForReclassificationApiURL);
        let convertedUName = uname.toLowerCase();
        const reqBody = { username: convertedUName };
        const decryptedValueForBaseURL = CryptoJS.AES.decrypt(localStorage.getItem('BaseURL'), secretKey).toString(CryptoJS.enc.Utf8);

        const postData = await this.http.post<any>(decryptedValueForBaseURL + '/api/v1/auth-service/sso-login', reqBody).toPromise();
        if (postData?.message == 'User not found.') {
          swal.fire({
            title: "Unauthorized",
            html: `<small>Please contact <b><a href="mailto:${this.mailTo}"><u>Support Team</u></a></b> for any login issues.</small>`,
            icon: "error",
            confirmButtonColor: "#3085d6",
            confirmButtonText: "Ok!",
          })
            .then((result) => {
              this.authService.logoutRedirect();
            });
        } else {
          //await this.authTs.getMailboxDetails();
          await this.authTs.GetUserRoles(postData.USER_ID);

          const encryptedValueForUName = CryptoJS.AES.encrypt(convertedUName, secretKey).toString();
          const encryptedValueForUserName = CryptoJS.AES.encrypt(postData.USER_NAME, secretKey).toString();
          const encryptedValueForUserId = CryptoJS.AES.encrypt(JSON.stringify(postData.USER_ID), secretKey).toString();
          const encryptedValueForUserEmail = CryptoJS.AES.encrypt(postData.EMAIL, secretKey).toString();
          const encryptedValueForUserToken = CryptoJS.AES.encrypt(postData.token, secretKey).toString();
          const encryptedValueForIsAuthSuccessful = CryptoJS.AES.encrypt('true', secretKey).toString();
          const encryptedValueForSessionTimer = CryptoJS.AES.encrypt(JSON.stringify(postData.sessionTimer), secretKey).toString();

          localStorage.setItem('UserEmail', encryptedValueForUserEmail);
          localStorage.setItem('UserToken', encryptedValueForUserToken);
          localStorage.setItem('IsAuthSuccessful', encryptedValueForIsAuthSuccessful);
          localStorage.setItem('SessionTimer', encryptedValueForSessionTimer);
          localStorage.setItem('uname', encryptedValueForUName);
          localStorage.setItem('UserName', encryptedValueForUserName);
          localStorage.setItem('UserId', encryptedValueForUserId);
          this.router.navigate(['/dashboard']);
          resolve(true);
        }
      } catch (error) {
        console.error('Error during SSO login:', error);
        reject(error);
      }
    });
  }


  logout(popup?: boolean) {
    if (popup) {
      this.authService.logoutPopup({
        mainWindowRedirectUri: "/"
      });
    } else {
      this.authService.logoutRedirect();
    }
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
