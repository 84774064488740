<!-- Sidebar Header starts -->
<div class="sidebar-header" *ngIf="config?.layout.menuPosition === 'Side'">
  <div class="logo clearfix">
    <a  class="logo-text float-left">

      <div class="logo-img">
        <img class="ml-n2" [src]="logoUrl" width="auto" height="50" />
      </div>
      <span class="text align-middle font-medium-1 " style="color:black; font-family: Inter var;"><b>CONTINUFLOW</b></span>
    </a>
    <a class="nav-toggle d-none d-lg-none d-xl-block" style="color: black;" id="sidebarToggle" href="javascript:;" (click)="toggleSidebar()">
      <i #toggleIcon [ngClass]="{
        'ft-toggle-left': config.layout.sidebar.collapsed,
        'ft-toggle-right': !config.layout.sidebar.collapsed
      }" class="toggle-icon"></i>
    </a>
    <!-- <a class="nav-toggle d-none d-sm-none d-md-none d-lg-block" id="sidebarToggle" href="javascript:;">
      <i #toggleIcon appSidebarToggle class="ft-toggle-right toggle-icon" data-toggle="expanded"></i>
  </a> -->
    <a class="nav-close d-block d-lg-block d-xl-none" id="sidebarClose" href="javascript:;" (click)="CloseSidebar()">
      <i class="ft-x"></i>
    </a>
  </div>
</div>
<!-- Sidebar Header Ends -->

<!-- Sidebar Content starts -->
<div class="sidebar-content main-menu-content" [perfectScrollbar] [disabled]="!perfectScrollbarEnable">
  <div class="nav-container" style="color:black;  font-family: Inter var; font-weight: 900;">
    <ul class="navigation" appSidebarDropdown>
      <!-- First level menu -->
      <li appSidebarlink *ngFor="let menuItem of menuItems" [hidden]="!menuItem.Role" [parent]="menuItem.title" [path]="menuItem.path" style="color:black"
        level="{{level + 1}}" [hasSub]="menuItem.class.includes('has-sub') ? true: false"
        [ngClass]="{'has-sub': menuItem.class.includes('has-sub') ? true: false}"
        [routerLinkActive]="menuItem.submenu.length != 0 ? 'open' : 'active'"
        [routerLinkActiveOptions]="{exact: false}">
        <a appSidebarAnchorToggle *ngIf="menuItem.submenu.length > 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]" style="color:black"></i>
          <span class="menu-title" style="color:black">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' "
            [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a appSidebarAnchorToggle routerLink="{{menuItem.path}}" [hidden]="!menuItem.Role"
          *ngIf="menuItem.submenu.length === 0 && !menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]" style="color:black"></i>
          <span class="menu-title" style="color:black">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' "
            [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <a [href]="[menuItem.path]" [hidden]="!menuItem.Role" target="_blank" *ngIf="menuItem.isExternalLink">
          <i [ngClass]="[menuItem.icon]" style="color:black"></i>
          <span class="menu-title" style="color:black">{{menuItem.title | translate }}</span>
          <span *ngIf="menuItem.badge && menuItem.badge != '' "
            [ngClass]="[menuItem.badgeClass]">{{menuItem.badge}}</span>
        </a>
        <!-- Second level menu -->
        <ul appSidebarDropdown class="menu-content"  *ngIf="menuItem.submenu.length > 0 " style="color:black" [hidden]="collapseSidebar">
          <li appSidebarlink *ngFor="let menuSubItem of menuItem.submenu" [parent]="menuItem.title"  [hidden]="!menuSubItem.Role" 
            [hasSub]="menuSubItem.class.includes('has-sub') ? true: false" [path]="menuSubItem.path"
            level="{{level + 2}}" [ngClass]="{'has-sub': menuSubItem.class.includes('has-sub') ? true: false}"
            [routerLinkActive]="menuSubItem.submenu.length != 0 ? 'open' : 'active'">
            <a appSidebarAnchorToggle *ngIf="menuSubItem.submenu.length > 0 && !menuSubItem.isExternalLink">
              <i [ngClass]="[menuSubItem.icon]" style="color:black"></i>
              <span class="menu-title" style="color: black;">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                [ngClass]="[menuSubItem.badgeClass]" style="color: black;">{{menuSubItem.badge}}</span>
            </a>
            <!-- [hidden]="!menuItem.submenu.Role"    -->
            <a appSidebarAnchorToggle routerLink="{{menuSubItem.path}}" [hidden]="!menuSubItem.Role" 
              *ngIf="menuSubItem.submenu.length === 0 && !menuSubItem.isExternalLink" style="color:black">
              <i [ngClass]="[menuSubItem.icon]" style="color:black"></i>
              <span class="menu-title" style="color:black">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                [ngClass]="[menuSubItem.badgeClass]" style="color:black">{{menuSubItem.badge}}</span>
            </a>
            <!-- [hidden]="!menuItem.submenu.Role" -->
            <a routerLink="{{menuSubItem.path}}"     target="_blank" *ngIf="menuSubItem.isExternalLink" [hidden]="!menuSubItem.Role" >
              <i [ngClass]="[menuSubItem.icon]" style="color:black"></i>
              <span class="menu-title" style="color:black">{{menuSubItem.title | translate }}</span>
              <span *ngIf="menuSubItem.badge && menuSubItem.badge != '' "
                [ngClass]="[menuSubItem.badgeClass]" style="color:black">{{menuSubItem.badge}}</span>
            </a>
            <!-- Third level menu -->
            <ul appSidebarDropdown class="menu-content" *ngIf="menuSubItem.submenu.length > 0" [hidden]="collapseSidebar" >
              <li appSidebarlink *ngFor="let menuSubsubItem of menuSubItem.submenu" [parent]="menuItem.title"   [hidden]="!menuSubsubItem.Role" 
                [hasSub]="false" [path]="menuSubsubItem.path" level="{{level + 3}}" routerLinkActive="active"
                [routerLinkActiveOptions]="{exact: true}"
                [ngClass]="config.layout.menuPosition === 'Side' ? menuSubsubItem.class : ''">
                <a appSidebarAnchorToggle routerLink="{{menuSubsubItem.path}}"   [hidden]="!menuSubsubItem.Role" 
                  *ngIf="!menuSubsubItem.isExternalLink; else externalSubSubLinkBlock">
                  <i [ngClass]="[menuSubsubItem.icon]" style="color:black"></i>
                  <span class="menu-title" style="color:black">{{menuSubsubItem.title | translate }}</span>
                  <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                    [ngClass]="[menuSubsubItem.badgeClass]" style="color:black">{{menuSubsubItem.badge}}</span>
                </a>
                <ng-template #externalSubSubLinkBlock>
                  <a routerLink="{{menuSubsubItem.path}}" target="_blank"  [hidden]="!menuSubsubItem.Role" >
                    <i [ngClass]="[menuSubsubItem.icon]"></i>
                    <span class="menu-title">{{menuSubsubItem.title | translate }}</span>
                    <span *ngIf="menuSubsubItem.badge && menuSubsubItem.badge != '' "
                      [ngClass]="[menuSubsubItem.badgeClass]">{{menuSubsubItem.badge}}</span>
                  </a>
                </ng-template>
              </li>
            </ul>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</div>
<!-- Sidebar Content Ends -->
