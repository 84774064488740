// web-socket.service.ts

import { Injectable } from '@angular/core';
import { io, Socket } from 'socket.io-client';
import { Observable } from 'rxjs';
import { webSocketURL } from '../data/urls';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private socket: Socket;

  constructor() { 
    // var loc = new URL(webSocketURL.URL);
    // this.socket = io(loc.protocol+'//'+loc.host, { path: '/traffix-automation-api/socket.io' }); // Replace with your WebSocket server URL
    this.socket = io(webSocketURL.URL);
    // //console.log(this.socket);
  }

  // public sendOpenPdfEvent(myPdfId: string, userName: string): void {
  //   this.socket.emit('open-pdf', {'pdfId': myPdfId, 'userName': userName});
  // }

  // public getOpenedPdfNotifications(myPdfId: string): Observable<string> {
  //   return new Observable<string>((observer) => {
  //     this.socket.on('pdf-opened', (pdfDetails: any) => {
  //       if(myPdfId == pdfDetails.pdfId){
  //         observer.next(pdfDetails);
  //       }
  //     });
  //   });
  // }

  getSocket() {
    return this.socket;
  }
}
